.practisecomponentdiv{
	padding:15px 0;
	.container{
		max-width: 95%;
	.practiserow{
		width: 100%;
		padding: 20px 0px;
    border-bottom: 2px dashed $ash-color;
    align-items: center;
    margin:0;
    .practisecol{
    	padding-left:0;
		section{
			h4{
				font-size: 19px;
			}
			p{
				font-size: 15px;
				padding:0;
				margin: 0;
				// padding-top:0px;
			}
		}
	}
	}
}
}