.intro {
	margin-top: 40px;
	margin-bottom: 40px;
	.leftinfo {
		h2 {
			font-size: 23px;
			font-family: heebomedium;
			color: $primary-color;
			@include media-query-max(991px) {
				text-align: center;
			}
		}
		.intro_title {
			font-size: 15px;
			font-family: heeboregular;
		}
		.intro_specailist {
			font-size: 15px;
			text-decoration: none;
			list-style: none;
			padding: 0;
			color: $ash3-color;
			font-family: heeboregular;
		}
		.intro_desc {
			color: $ash1-color;
			font-size: 15px;
			font-family: heeboregular;
		}
		.introFooter {
			position: relative;
			bottom: -45px;
			@include media-query-max(991px) {
				position: initial;
				bottom: 0;
			}
			button {
				&.formbtn {
					padding: 7px 7px;
					outline: 0;
					border: 0;
					font-size: 14px;
					min-width: 120px;
					text-align: center;
					color: $white-color;
					margin-right: 7px;
					margin-bottom: 15px;
					img {
						width: 20px;
						height: 20px;
						margin-right: 10px;
					}
				}
				&.introwidth {
					min-width: 110px;
					a {
						color: $white-color;
						&:hover {
							text-decoration: none;
						}
					}
				}
			}
		}
		.introclinics {
			padding: 20px 0px;
			.clinicdiv {
				width: 130px;
				height: 130px;
				margin-right: 15px;
				padding: 5px;
				margin-bottom: 5px;
				text-align: center;
				display: inline-flex;
				align-items: center;
				justify-content: center;
				&.border {
					&.sand {
						box-shadow: 0px 3px 6px #00000029;
					}
					&.sky {
						box-shadow: 0px 3px 6px #00000029;
					}
				}
				img {
					width: 100%;
				}
			}
		}
	}
	.mobileimage {
		display: none;
		padding: 15px 0px;
		width: 250px;
		text-align: center;
		margin: 0 auto;
		img {
			width: 100%;
			height: auto;
		}
		@include media-query-max(991px) {
			display: block;
		}
	}
	.rightinfo {
		.customimg {
			// width: 350px;
			display: block;
			@include media-query-max(991px) {
				display: none;
			}
			// margin-left: auto;
			img {
				width: 100%;
				height: auto;
			}
		}
	}
}
