.conditional_treatment_div{
	.container{
		// padding-top: 15px;
		// padding-bottom: 15px;
	}
	.info_sharebox{
		background: $ash8-color;
		padding-top:15px;
		.inforow{
			@include media-query-max(768px){
				padding:0px 15px!important;
			}
		}
		a{
			&:hover{
			text-decoration: none;
			}
		.pdftitle{
			font-family: robotobold;
			color:$black-color;			
		}

		}
	}
	.conditional_social_icons{
		.shareimage{
			@include media-query-max(991px){
				justify-content:flex-start;
			}

						.customimg{
				@include media-query-max(768px){
					width: 100%;
					padding-top:10px;
				}
				img{

				}
			}
		.share{
			font-weight: bold;
			padding:0 20px;
			@include media-query-max(991px){
				padding:5px 20px;
			}
			@include media-query-max(768px){
				padding:0px 0px;
				display: block;
				width: 100%;
				padding-top:15px;
				padding-bottom:0px;

				// text-align: center;
			}
		}
	}
		.sociallists{
			li{
					opacity: 1;
					background:transparent;
					@include media-query-max(991px){
						margin-left: -15px;
					}

				i{
					font-size: 24px;
					color:$primary-color;
					&:hover{
						color:$primary-color!important;
					}
				}
				&:hover{
					background:transparent;
					i{
						color:$primary-color!important;
					}

				}
			}
		}
	}
	.middle_section{
	margin:0 0px;
	margin-right: 5px;	
	.all_info{
			list-style: none;
			padding-left:0;
		a{
			padding:15px 20px;
			background:$ash6-color;
			margin-bottom: 2px;
			font-size: 16px;
			display: block;
			font-family: robotomedium;
			color:$ash3-color;
			text-decoration: none;
			&:hover{
				background: $primary-color;
				color:$white-color;
				
			}
			&.active{
				background: $primary-color;
				color:$white-color;
			}
			
		}

	}
	.middle_div_col{
		padding:0;
				margin:0;
				padding-top:15px;
		.quicklink{
			padding:0 15px;
			h2{
				font-family: robotoregular;
				padding-bottom:8px;
			}			
				a{
				font-family: robotoregular;
				text-decoration: none;
				display: block;
				color:$primary-color;
				padding-bottom:10px;
				cursor:pointer;
				font-size: 18px;
				outline: 0;
				}
		}
	}
	.right_content{
		.rightbannercol{
			@include media-query-max(768px){
				padding: 0 0;
			}
		padding-bottom: 20px;
}
		// padding-right:20px;
	}
	.conditional_right_banner{
		// width: 250px;
		height: 250px;
		// margin:15px 0px;
		margin-left: auto;
		margin-top: 25px;
	}
	}
	.bodysection{
		padding:15px;
		*{
			color:$ash1-color;
			font-size: 16px;
			padding: 0;
			margin: 0;
		}
		.quicklink_section{
			padding:5px 0px;
			.bgtitle{
				padding: 8px 6px;
margin-bottom: 5px;
border-radius: 5px;
background:$ash8-color;
			}
		}
		h2{
			font-family: robotobold;
			color:$black-color;
			font-size: 18px;
			padding-bottom:15px;
		}
		.para_title{
			font-weight: bold;
			color:$black-color;
		}
		p{
			padding-bottom:15px;
		}
		.conditional_images{
			.condition_banner_col{
				// padding:15px;
				max-width:49%;
				@include media-query-max(768px){
				max-width:initial;
				}
				padding-bottom:15px;
				.conditional_banner{
					width: 100%;
					height: auto;
				}
				p{
					padding:10px 0px;
				}
			}
		}
		.conditional_list{
			li{
				list-style: none;
				display: flex;
				align-items:flex-start;
				margin-left: 15px;
				// line-height: 1.5;
				padding-bottom: 10px;
				i{
					padding-right:10px;
					position: relative;
					top:7px
				}
				span{
					padding:0;

				}
			}
		}
		.avatarimage{
			padding-bottom:8px;
			border-bottom:1px dashed $black-color;
			margin-bottom: 12px;
			.avt_title{
				font-weight: bold;
				padding-top:5px;
				color:$primary-color;
				margin-bottom:10px ;
				font-size: 30px;
				line-height: normal;
			}
			.avt_h1,.avt_h2{
				padding:0;
				font-size: 18px;
				line-height: normal;
			}
			.avt_h1{
				font-weight: bold;
				padding-top:5px;
				color:$primary-color;
			}
			.avt_h2{
				padding-top:3px;
			}
			.banner_avt_img{
				width: 250px;
				height: 250px;

				@include media-query-max(768px){
					width: 100%;
					height: 320px;
				}
			}
			.avt_right{
				text-align: right;
				@include media-query-max(768px){
					text-align: left;
				}
				.avt_right_flex{
					.avt_rightcontent{
						@include media-query-max(1241px){
						order:2;
						margin-left: 0;
						width: 100%;
						h5{
							margin-top: 15px;
						}
						h5,p{
							margin-left: auto;
						}
						}

					}
						@include media-query-max(768px){
							margin-top: 15px;
						}
				}
			.avt_rightcontent{
				h5{
					color: $ash3-color;
					font-size: 18px;
					padding-bottom: 5px;
					font-weight: bold;
				}
				p{
					font-family: heeboregular;
					font-size: 16px;
					margin: 0;
					line-height: 1.3em;
					color: $ash1-color;
					width: 350px;
				}
			}
			.svgicon {
				// float:right;
				margin-left:15px;
				@include media-query-max(768px){
				margin-left:0px;
				margin-right:15px;
				margin-top: 12px;
				}
				>img,svg{
				margin:auto;
			}
		}
	}
		}

	}
	.mobilediv{
		display: none;
	}
		&.patients_div{
			padding:0;
			.bodysection{
				padding:0;
			.nav-tabs{
			// justify-content:space-between;
			border:0;
			a{
				width: 24.9%!important;
				background:$ash6-color;
				color:$ash3-color;
				font-family: robotomedium;
				border:0;
				outline:0;
				border-radius: 0;
				padding:10px 10px;
				&.active{
				background:$primary-color;
				color: $white-color;
				}
				@include media-query-max(991px){
					width: 100%!important;
					margin-bottom: 5px;
				}
				&.tabmarginright{
					margin-right: 0.1%;
					margin-left: 0.1%;
				}
			}
		}
		.tab-content{
			padding:15px;
			p{
				font-family:heeboregular
				// color:$ash3-color;
			}
			.conditional_link{
				p{
					font-family: heebomedium;
					padding-bottom:5px;
					color:$black-color;
				}
				.anchorlink{
					cursor: pointer;
					font-family: heebomedium;
					color:$primary-color;
				}
			}
		}
		}	
		}
	@include media-query-max(991px){
		.mobilediv{
			display: block;
			margin-top: 15px;
			h2{
				font-family: robotoregular;
			}
		}
		.all_info{
			display: none;
		}
	}
}

.conditional_div{
	ul
	{
	padding:0;
	list-style:none;
	li{
	padding:10px 15px;
	background-color:$ash2-color;
	margin:5px;
	a{
	font-family:robotoregular;
	color:$ash3-color;
	font-size:18px;
	text-decoration:none;
	cursor:pointer;
	}
	&:hover{
	a{
	text-decoration:none;
		color:$primary-color;
	}
	}
	}
	}
	.mobileTabsWrapper{
	&.active{
	background-color:rgba(233, 233, 233, 0.6);
	}
	li{
	background-color:$white-color;
	}
	.container{
	padding:15px 15px;
	}
	}
	.testimonaltabs{
	justify-content:space-between;
	}
}