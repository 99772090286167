.staffcomponentdiv{
	padding:15px 0;
	.container{
		max-width: 95%;
	.staffrow{
		width: 100%;
		padding: 30px 0px;
    align-items: center;
    margin:0;
    .staffcol{
    	padding-left:0;
    	text-align:center;
    border-right: 2px dashed $ash2-color;
    &.last{
    	border-right:none;
    }
		section{
			display: inline-block;
			text-align: left;
			h4{
				font-size: 19px;
			}
			p{
				font-size: 15px;
				padding:0;
				margin: 0;
				// padding-top:0px;
			}
			margin-bottom: 15px;
		}
		.staffbanner{
			width: 280px;
			height:300px;
			margin:0 auto;
			background-position: top;
			border:1px solid $ash-color;
			@include media-query-max(1180px){
			width: 85%;
			}
		}
	}
	}
}
}