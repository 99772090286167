.footer_div{
	background:$primary-color;
	position: relative;
	.scrolltop{
		box-shadow: 0px 3px 15px #00000069;
		width: 60px;
height: 60px;
display: inline-block;
border-radius: 50%;
position: absolute;
background: $white-color;
// bottom: 0;
top: 70px;
z-index: 9;
left: 0;
right: 0;
margin: auto;
display: inline-flex;
align-items: center;
justify-content: center;
cursor: pointer;
i{
color:$primary-color;
font-size: 28px;
}
	}

	.additonal_footer{
		background:$light-blue-color;
		clip-path: polygon(0 0, 100% 0%, 50% 50%, 0% 100%);
		height: 200px;
		&.white{
			background:$white-color;
		}
	}
	.container{
		padding-top:10px;
		padding-bottom:10px;
	}
	.ourclinics{
			font-family: heebomedium;
			color:$white-color;
		h4{
			font-size: 23px;
			padding-bottom: 8px;
		}
		h5{
			font-size: 18px;
		}
		p{
			font-size: 15px;
			font-family: heeboregular;
		}
	}
	.getintouch{
			font-family: heebomedium;
			color:$white-color;
			@include media-query-max(991px){
				margin-top: 15px;
			}
		h4{
			font-size: 23px;
			padding-bottom: 8px;
		}
		.iconbox{
		.customimg{
			    display: flex;
    			align-items: center;
    			justify-content: center;
    			border:1px solid $white-color;
    			padding-top:0px;
    			
    		}
    		span{
    			color:$white-color;
    		}

		}
		h5{
			font-size: 18px;
		}
		p{
			font-size: 15px;
			font-family: heeboregular;
		}
		.footersocialicons{
			margin-top: 30px;
			@include media-query-min(992px){
			margin-top: 50px;
		}
		}
	}
	.footerlinks{
		@include media-query-min(992px){
			margin-top: -50px;
		}
		

		ul.sociallists{
		li{
			opacity:1;
			background-color:transparent;
			a{
				i{
					color:$white-color;
				}
			}
			&:hover{
				background-color:transparent;
				a{
				i{
				color:$white-color;
				}
			}
			}
		}
	}
	.anchorlinks{
		padding-left:15px;
		padding-top:5px;
		a{
			width: 100%;
			text-decoration: none;
			display: block;
			color: $white-color;
			padding-bottom:5px;
		}
	}
	@include media-query-max(991px){
			display: flex;
			flex-direction: column;
			.anchorlinks{
			padding-left:0;
			margin-top: 15px;
			display: flex;
			flex-wrap: wrap;
			a{
				width: auto;
				padding-right:20px;
			}
			}
			ul{
				order:1;
			}
		}
}
}

