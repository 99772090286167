.treatmentcomponentdiv{
	.container{
		max-width: 95%;
		padding:25px 0px;
	.treatmentrow{
		width: 100%;
		.treatmentcol{
			border-right: 2px dashed $ash4-color;
			&.last{
				border-right:none;
			}
			ul{
				list-style: none;
				li{
					font-size: 16px;
					padding:5px 0;
			a{
				white-space: pre-wrap;
				color:$ash3-color;
				font-family: robotomedium;
				text-decoration: none;
				&:hover{
					color:$primary-color;
				}
			}
		}
		}
		.treatmentbanner{
			height: 100%;
			width: 100%;
			background-position: top;
			margin-left:20px;
			background-size:contain;
		}
		}
	}
}
}