.personalblogdiv{
	background-color: $blog-bgcolor;
	padding:25px 0px;
	h2{
		text-align: center;
        font-size: 45px;
        padding: 10px;
        color: $primary-color;
        margin-bottom: 20px;
        font-family: robotoregular;
        @include media-query-max(768px)
        {
            font-size: 30px;
        }
	}
	.flexcol-blog{
		padding-bottom:40px;
	}
	.card-body{
		padding:12px;
	}
	.viewmore{
		display: block;
		text-align: right;
		padding: 15px 0px;
		a{
			padding:6px 15px;
			color:$white-color;
			background-color: $primary-color;
			display: inline-block;
			&:hover{
				text-decoration: none;
			}
		}	
	}
}
.polygonimage{
	width: 100%;
	height: 100%;
	clip-path: polygon(0 0, 100% 0, 75% 100%, 0% 100%);
}
	.svgicon{
    border: 1px solid $primary-color;
    display: inline-block;
    width: 34px;
    height: 34px;
    border-top-left-radius: 8px;
    margin-right: 8px;
    position:relative;
		svg,img{
			width: 19px;
			fill:$primary-color;
			position: absolute;
			left:0;
			right: 0;
			bottom:0;
			top:0;
			margin: auto;

			// height: 15px;
		}
	}
	.personalCard{
		border-radius:0;
		border-top-right-radius:8px;
		box-shadow: 0px 3px 6px #00000029;
		// margin-bottom: 40px;
		height: 100%;
		.peronalrow{
			height:100%;
			.card-body{
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				height: 100%;
			}
		}
		p{
			font-size: 14px;
			color:$ash1-color;
			font-family: heeboregular;	
			@include line-clamp(16,1.5,5);
		}
		a{
			font-size: 13px;
			color:$ash1-color;
			&:hover{
				text-decoration: none;
			}
		}
		.readmorerow{
			align-items:flex-end;
		}
	}