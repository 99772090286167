.youtubevideodiv{ 
	padding: 0px 0px 0px 0px;
	h2{
		text-align: center;
        font-size: 45px;
        padding: 20px 0px;
        color: $primary-color;
        margin-bottom: 20px;
        font-family: robotoregular;
        @include media-query-max(768px)
        {
            font-size: 30px;
        }
	}
	.partnercard{
		margin-bottom: 25px;
		border-radius: 0;		
	}
	.visitchannel{
		display: block;
		text-align: right;
		padding: 15px 0px;
		a{
			padding:6px 15px;
			color:$white-color;
			background-color: $primary-color;
			display: inline-block;
			&:hover{
				text-decoration: none;
			}
		}	
	}
}
.customerslidermain{
	position:relative;
	border: 1px solid #e8e4e4;
	box-shadow: -2px 2px 3px 5px #ececec;

	// background-color:$ash4-color;
&:before{
	content: "";
	width: 100%;
	height: 100%;
	position:absolute;
	top: 0;
	left:0;
	background: $white-color 0% 0% no-repeat;
	opacity: 0.55;

}
	.customimg{
		img{
			width: auto;			
		}
	}
	.slick-slide{
		padding: 22px 17px;
		background-color:transparent;
		// width: 150px;
		img{
			height: 45px;
			width: auto;
			height: 130px;
		}
		
	}
	.slick-arrow{
					// padding:0px;
			display: none!important;
		
	}
	.imagesliderwidth{
		// width: 150px;
		border-top-left-radius:15px;
		background-color: $white-color;
		height: 66px;
    padding: 6px;
    box-shadow: 0 0 5.5px black;
    outline: 0;
    border:0;
		div{
		background-size:contain;
		background-position:center;
			height: 100%;
			width: 100%
		}
		
	}
}