.blog_div{
	@include media-query-max(991px){
	.order1{
		order:1;
	}
	.order2{
		order:2;
	}
	}
	.highlightdiv{
		margin:12px 0;
		header{
			padding: 15px;
    border-left: 5px solid #03a9f4;
    background: $ash7-color;
    margin: 15px 0px;
		}
		a{
			text-decoration: none;
			color:$ash1-color;
		}
		input{
			box-shadow: none;
			border-radius:0;
			border-top-left-radius:12px;
			padding:10px;
		}
		.input-group-append{
			span{
			background:$primary-color;
			color:$white-color;
		}
		}
		ul{
			list-style: none;
			padding: 0;
			li{
				display: flex;
				padding: 5px 0px;
				align-items: center;
				font-family: heeboregular;
				color:$ash1-color;
				font-size: 18px;
			i{
				padding-right:8px;
			}
			}
		}
	}
	.mobile_blog_posts{
		display: none;
		@include media-query-max(991px){
			display: block;
		}
	}
	.web_blog_posts{
		display: none;
		@include media-query-min(992px){
			display: block;
		}
	}
}