.curvedCard{
    border-radius: 0;
    &.left{
        border-top-left-radius: 25px;
    }
    &.right{
        border-top-right-radius: 25px;
    }
    .curvedcard_body{
        z-index: 9;
        background-color: white;
    }
}