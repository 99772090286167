.partnersdiv {
	background-color: $light-blue-color;
	padding: 25px 0px;
	h2 {
		text-align: center;
		margin: 0;
		color: $primary-color;
		font-size: 45px;
		margin-bottom: 15px;
		font-family: robotoregular;
		@include media-query-max(768px) {
			font-size: 30px;
			margin-bottom: 0px;
		}
	}
	.partnerslider {
		.slick-arrow {
			width: auto;
			height: auto;
			z-index: 1;
			// margin: -10px;
			&:before {
				content: "";
			}
			&.slick-disabled {
				opacity: 0.5;
			}
		}
		.customarrows {
			font-size: 16px;
			width: 40px;
			height: 40px;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 50%;
			color: $white-color;

			background-color: $primary-color;
			&.left {
				position: relative;
				// left: -8px;
			}
			&.right {
				position: relative;
				// right: -8px;
			}
			@include media-query-min(1023px) {
				&.left {
					// position: relative;
					left: -8px;
				}
				&.right {
					// position: relative;
					right: -8px;
				}
			}
			@include media-query-max(1023px) {
				display: none;
			}
			@include media-query-max(991px) {
				display: flex;
				font-size: 16px;
				width: 35px;
				height: 35px;
				font-size: 12px;
			}
			@include media-query-max(600px) {
				display: none;
			}
		}
		.slick-slide {
			padding: 15px;
			.wrapperpartner {
				outline: 0;
			}
		}
	}
}
.partnercard {
	border-top-left-radius: 20px;
	box-shadow: 0px 3px 6px #00000029;
	outline: 0;
	&.noborder {
		border: 0;
	}
	iframe {
		border: 0;
	}
	&:foucs {
		outline: 0;
	}
	.imagebox {
		height: 200px;
		width: 100%;
		@include media-query-max(768px) {
			// height: auto;
			height: 250px;
			img {
				width: 100%;
				height: auto;
			}
			iframe {
			}
		}
	}
	.card-title {
		font-family: heebomedium;
		font-size: 18px;
		text-align: center;
		color: $primary-color;
		&.blacktitle {
			color: $black-color;
			padding-top: 12px;
			margin: 0;
		}
	}
	.card-text {
		font-family: heeboregular;
		font-size: 15px;
		line-height: normal;
		color: $ash-color;
		@include line-clamp(15, 1.5, 5);
	}
	.readmore {
		display: block;
		text-align: right;
		font-size: 14px;
		color: $ash-color;
		cursor: pointer;
		a {
			color: $ash-color;
			&:active,
			&:hover {
				color: $ash-color;
				text-decoration: none;
			}
		}
	}
	.circleavatarobj {
		margin: 15px 0px;
		display: flex;
		align-items: center;
		* {
			padding: 0;
			margin: 0;
		}
		.avatar_desc {
			padding-left: 10px;
			font-family: robotomedium;
			h5 {
				color: $ash3-color;
				font-size: 15px;
				padding-bottom: 5px;
			}
			p {
				font-family: heeboregular;
				font-size: 14px;
				margin: 0;
				line-height: 1.3em;
				color: $ash1-color;
			}
		}
		.circleimagecontent {
			width: 90px;
			height: 90px;
			border-radius: 50%;
			box-shadow: 0px 3px 6px #00000029;
		}
	}
}



/* Flashing */
.hover13 figure:hover img {
	opacity: 1;
	-webkit-animation: flash 1.5s;
	animation: flash 1.5s;
}
@-webkit-keyframes flash {
	0% {
		opacity: .4;
	}
	100% {
		opacity: 1;
	}
}
@keyframes flash {
	0% {
		opacity: .4;
	}
	100% {
		opacity: 1;
	}
}


  /* Circle */
.hover15 figure {
	position: relative;
}
.hover15 figure::before {
	position: absolute;
	top: 50%;
	left: 50%;
	z-index: 2;
	display: block;
	content: '';
	width: 0;
	height: 0;
	background: rgba(255,255,255,.2);
	border-radius: 100%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	opacity: 0;
}
.hover15 figure:hover::before {
	-webkit-animation: circle .75s;
	animation: circle .75s;
}
@-webkit-keyframes circle {
	0% {
		opacity: 1;
	}
	40% {
		opacity: 1;
	}
	100% {
		width: 100%;
		height: 100%;
		opacity: 0;
	}
}
@keyframes circle {
	0% {
		opacity: 1;
	}
	40% {
		opacity: 1;
	}
	100% {
		width: 100%;
		height: 100%;
		opacity: 0;
	}
}

.hover14 figure {
  position: relative;
}
.hover14 figure::before {
  position: absolute;
  top: 0;
  left: -75%;
  z-index: 2;
  display: block;
  content: '';
  width: 50%;
  height: 100%;
  background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,.3) 100%);
  background: linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,.3) 100%);
  -webkit-transform: skewX(-25deg);
  transform: skewX(-25deg);
}
.hover14 figure:hover::before {
  -webkit-animation: shine .75s;
  animation: shine .75s;
}
@-webkit-keyframes shine {
  100% {
    left: 125%;
  }
}
@keyframes shine {
  100% {
    left: 125%;
  }
}
