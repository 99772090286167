.insurer_main{
    margin-top: 40px;
    margin-bottom: 40px;
    .title{
        text-align: center;
        font-size: 45px;
        padding: 10px;
        color: $primary-color;
        margin-bottom: 20px;
        font-family: robotoregular;
        @include media-query-max(768px)
        {
            font-size: 30px;
        }
    }
    .bottom_space{
        margin-bottom: 40px;
    }
    .imagecol{
        margin-bottom: 25px;
        text-align: center;
       @include media-query-min(992px)
        {
            max-width: 20%;
            flex: 0 0 20%;
        }
        .img_text{
            text-align: center;
        }
    }
    .img_text{
            text-align: left;
            // font-family: Medium 25px/33px IBM Plex Sans;
            letter-spacing: 0px;
            color: #727E8C;
            font-size: 23px;
            opacity: 1;
            margin-top: 5px;
        }
    .align_self{
        text-align: center;
        }
    
}