
.fixedmenu{
	position: fixed;
	right: 0;
	margin-top: 15px;
	z-index: 999;
	&.bottom{
		bottom: 180px;
	}
	&.hide{
		display: none;
	}

	.menu_fixed{
		.menubox{
			width: 70px;
			height: 70px;
		background: $white-color;
		// display: flex;
		text-align: center;
		// display: table;
		// vertical-align: middle;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
			margin-bottom: 10px;
			border-top-left-radius: 8px;
			// padding:10px 0px;
			box-shadow: 0px 1px 15px #0000001A;
			.bannermenu{
			height: 40px;
			width: 40px;
margin: 0 auto;
background-size: contain;
&.secondary{
	width: 54px;
	height: 54px;
}
			}
			p{
				font-size: 12px;
				text-align: center;
				padding-top:5px;
				margin:0;
			}
			&:hover{
				background:$primary-color;
				p{
					color: $white-color;
				}
			}
		}
	}
}
.appointmentFormDiv{
	.appointmentForm{
		label{
				width: 100%;
				padding-bottom:10px;
			input{
				width: 100%;
				padding:10px;
				outline: 0;
				border:1px solid $ash4-color;
			}
			.errordiv{
				color:$red-color;
				padding-top:5px;
			}
		}
		.customcheckbox{
			margin-left:0;
		}
		.formbtn{
			width: 100%;
			padding:12px;
			color:$white-color;
			outline:0;
			border:0;
			font-family: heebobold;
		}
	}
}