.virtualconsulation_div {
	header {
		// background: $primary-color;
		padding: 30px 24px;
		h2 {
			font-family: robotomedium;
			padding: 0;
			margin: 0;
			color: $white-color;
			@include media-query-max(768px) {
				font-size: 20px;
			}
		}
	}
	.bannerimage {
		height: 150px;
		background-position: center right;
		@include media-query-max(768px) {
			height: 250px;
		}
		@include media-query-max(550px) {
			height: 200px;
		}
		@include media-query-max(400px) {
			height: 170px;
		}
	}

	.mapcontentdiv {
		margin-top: 25px;
		@include media-query-max(768px) {
			display: none;
		}
	}
	.hospitals {
		background: $ash5-color;
		padding: 15px 0px;
		.container {
			> h5 {
				color: $ash1-color;
				font-size: 16px;
				font-family: robotmedium;
				margin-bottom: 15px;
			}
		}
		.hospital_div {
			height: 180px;
			border-top-left-radius: 15px;
		}
		.hospital_img {
			margin-bottom: 15px;
			cursor: pointer;
		}
		img {
			width: 100%;
			height: auto;
		}
		.hospital_title {
			font-size: 18px;
			color: $ash1-color;
			margin: 0;
			padding: 0;
			margin-top: 5px;
			font-family: robotomedium;
			&.active {
				color: $primary-color;
			}
		}
	}
	.formtexts {
		text-align: center;
		padding: 40px 0px;
		* {
			padding: 0;
			margin: 0;
			font-family: robotoregular;
		}
		span {
			color: $red-color;
		}
	}
	.radioform {
		* {
			font-family: robotoregular;
		}

		.radiobtn {
			display: flex;
			.radiocontainer {
				display: block;
				position: relative;
				padding-left: 35px;
				margin-bottom: 12px;
				cursor: pointer;
				font-size: 14px;
				-webkit-user-select: none;
				-moz-user-select: none;
				-ms-user-select: none;
				user-select: none;
				margin-left: 25px;
				@include media-query-max(991px) {
					margin-left: 0px;
				}
				min-width: 120px;
				.optionname {
					position: relative;
					top: 1px;
				}
			}

			/* Hide the browser's default radio button */
			.radiocontainer input {
				position: absolute;
				opacity: 0;
				cursor: pointer;
				width: 100%;
				height: 100%;
				left: 0;
				top: 0;
			}

			/* Create a custom radio button */
			.checkmark {
				position: absolute;
				top: -2px;
				left: 0;
				height: 25px;
				width: 25px;
				background-color: $white-color;
				border-radius: 50%;
				box-shadow: 0px 5px 10px #00000029;
			}

			/* On mouse-over, add a grey background color */

			/* When the radio button is checked, add a blue background */
			.radiocontainer input:checked ~ .checkmark {
				background-color: white;
			}
			.radiocontainer input:not(:checked) ~ .checkmark {
				/* Explicit Unchecked Styles */
				border: 5px solid #00000029; // Only apply border to unchecked state
			}

			/* Create the indicator (the dot/circle - hidden when not checked) */
			.checkmark:after {
				content: "";
				position: absolute;
				display: none;
			}

			/* Show the indicator (dot/circle) when checked */
			.radiocontainer input:checked ~ .checkmark:after {
				display: block;
			}

			/* Style the indicator (dot/circle) */
			.radiocontainer .checkmark:after {
				top: 4px;
				left: 4px;
				width: 70%;
				height: 70%;
				border-radius: 50%;
				background: $primary-color;
			}
			.radiocontainer.squarecontainer {
				margin-left: 25px;
				padding-left: 40px;
				min-height: 30px;
				display: flex;
				align-items: center;
				.optionname {
					position: relative;
					top: 0px;
				}
				.checkmark {
					border-radius: 4px;
					width: 25px;
					height: 25px;
					border: 1px solid #707070;
					box-shadow: lightgray;

					&:after {
						left: 10px;
						top: 4px;
						width: 8px;
						height: 14px;
						border: solid white;
						border-top-width: medium;
						border-right-width: medium;
						border-bottom-width: medium;
						border-left-width: medium;
						border-width: 0 3px 3px 0;
						-ms-transform: rotate(45deg);
						transform: rotate(38deg);
						border-radius: 0;
					}
				}
				input:checked ~ .checkmark {
					background: $primary-color;
					border: 0;
				}
				input:not(:checked) ~ .checkmark {
					/* Explicit Unchecked Styles */
					border: 1px solid #00000029; // Only apply border to unchecked state
				}
			}
		}
	}
	.ConsultForm {
		border: 1px solid $ash-color;
		padding: 15px 30px;
		@include media-query-max(991px) {
			padding: 15px 15px;
		}
		label {
			display: block;
			color: $ash1-color;
			.mandatory {
				color: $red-color;
			}
		}
		.errordiv {
			color: $red-color;
			margin-top: 4px;
		}
		input,
		textarea,
		select {
			padding: 7px 8px;
			border: 1px solid $ash-color;
			// border:0;
			width: 100%;
			resize: none;
			outline: 0;
			// border-top-left-radius:5px;
		}
		.consult_row {
			padding: 12px 0;
		}
		.formcol {
			padding-bottom: 12px;
			h4 {
				margin: 0;
				font-size: 18px;
				font-weight: bold;
			}
			.inline-block {
				display: inline-block;
				margin-bottom: 15px;
				margin-right: 15px;
				@include media-query-max(991px) {
					display: block;
					margin-right: 0;
				}
				input {
					padding: 12px;
					width: 100%;
				}

				&.captchadiv {
					background: #d9ddfc;
					position: relative;
					@include media-query-max(991px) {
						width: 100%;
					}
					input {
						border: 0;
						background: transparent;
						text-align: center;
						color: $primary-color;
						font-size: 18px;
						font-weight: bold;
					}
					i {
						font-family: "Font Awesome 5 Free";
						position: absolute;
						right: 5px;
						top: 0;
						bottom: 0;
						margin: auto;
						height: 15px;
						width: 15px;
						right: 10px;
						cursor: pointer;
						margin: auto;
						font-size: 12px;
						color: #727e8c;
					}
				}
			}
		}
		.formbtn {
			padding: 12px;
			min-width: 240px;
			outline: 0;
			border: 0;
			text-align: center;
			color: $white-color;
			font-size: 22px;
			font-family: robotomedium;
			box-shadow: 0px 3px 6px #00000029;
		}
	}
}
.rightcontactdiv {
	* {
		padding: 0;
		margin: 0;
		font-family: robotoregular;
	}
	h5 {
		color: $primary-color;
		font-size: 16px;
		font-family: robotomedium;
		padding-bottom: 8px;
	}
	p {
		font-size: 15px;
		padding-top: 0px;
		padding-bottom: 8px;
	}
	.contacticon {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		margin-bottom: 5px;
		.contactsvg {
			width: 26px;
			text-align: center;
		}
		.iconsvg {
			// width:23px;
			margin-right: 8px;
			* {
				stroke: $primary-color;
				fill: $primary-color;
			}
		}
		label {
			// width: 80%;
			font-size: 14px;
		}
	}
}
