.testimonal_div{
	&.media_div{
		.bannerimage{
			background-position: center left;
		}
	}
	.testimonaltabs{
		justify-content: center;
		border-bottom: 0px;
		a{
			width: 24.9%;
			text-align: center;
			background: $ash6-color;
			color: $ash3-color;
			font-weight: bold;
			border-radius: 0;
			outline:0;
			// font-family: robotomedium;
			font-family: robotoregular;
			&.active{
				background:$primary-color;
				color:$white-color;
				border:0;
			}
			&.tabmarginright{
				margin-right: 0.1%;
				margin-left: 0.1%;
			}
			@include media-query-max(991px){
				width: 100%;
				display: none;
			}
		}
		&.col3{
			a{
				width: 33.10%;
			}
		}
	}
	.tab-content{
		@include media-query-max(991px){
				width: 100%;
				display: none;
			}

	}
		.mobiletabs{
		justify-content: space-between;
		border-bottom: 0px;
		display: none;
		@include media-query-max(991px){
				display: block;
			}
		.mobiletab{
			width: 100%;
			display: block;
			padding:15px 120px;
			text-align: center;
			background: $ash6-color;
			color: $ash3-color;
			font-weight: bold;
			cursor: pointer;
			border-radius: 0;
			outline:0;
			margin-bottom: 5px;
			font-family: robotomedium;
			&.active{
				background:$primary-color;
				color:$white-color;
				border:0;
			}
		}
	}

	li{
		border:0;

	.page-link{
		border:0;
		color:$black-color;
	    padding: 12px 19px;
		border-radius: 0!important;
		&:hover{
			// background:$white-color;
		}
		&:focus{
			box-shadow: none;
		}
	}

}
.next_page{
		a{
			    padding: 4px 12px;
			// padding: 0 8px;
			span{
				font-size: 26px;
			}
			&:hover{
				background:none;
			}
		}
	}
.tab-content{
	margin:25px 0px;
}
}

.card-radient {
    background-image: linear-gradient(to right, #ffffff00 , #ffffffd4);
}