.max-lines{
	@include line-clamp(16,4,8)
}
.width100{
	@include media-query-max(1199px){
	max-width: 100%;
	flex:0 0 100%;
}
}
.customcheckbox{
	width: 92%;
	margin:0 auto;
	input{
		display: none;
	}
	.conditiontext{
		margin: 0;
		padding-left: 10px;
		font-family: heeboregular;
		font-size: 18px;
		a{
			background-color:transparent;
			color:$primary-color;
			cursor: pointer;
			&:hover{
				color:$primary-color;

			}
		}
	}
.radiocontainer {
	margin-left: 0px;
	min-height: 30px;
display: flex;
align-items: center;
position:relative;
	.checkmark{
		position:relative;
		border-radius:4px;
		width: 25px;
		height: 25px;
		border:1px solid #707070;
		box-shadow:none;
		&:after {
    content: '';
    left: 9px;
    top: 4px;
    width: 7px;
    height: 14px;
    border: solid white;
    border-top-width: medium;
    border-right-width: medium;
    border-bottom-width: medium;
    border-left-width: medium;
    border-width: 0 3px 3px 0;
    -ms-transform: rotate(45deg);
    transform: rotate(38deg);
    border-radius: 0;
    display: block;
    position: absolute;
}
	}
	input:checked ~ .checkmark{
		background:$primary-color;
		border:0;
	}
	input:not(:checked) ~ .checkmark {
  /* Explicit Unchecked Styles */
  border: 1px solid #00000029; // Only apply border to unchecked state
}
}
}
/* Top left text */
.iiz__hint {
    top: 10px;
    right: 10px;
	pointer-events: none;
	background: #03a9f4;
}

.patient_image {
	width: 120px;
    height: auto;
    margin: auto;
    display: block;
    top: 50 px;
    bottom: 0px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    border: 2px solid #fff;
    box-shadow: 10px 10px 5px #ccc;
    -moz-box-shadow: 10px 10px 5px #ccc;
    -webkit-box-shadow: 0px -1px 9px 3px #ccc;
    -khtml-box-shadow: 10px 10px 5px #ccc;
}
.blur {
    -webkit-filter: blur(3px);
    filter: blur(3px);
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

.pagination {
    display: -ms-flexbox;
    display: flex;
    padding-left: 0;
    list-style: none;
	border-radius: .25rem;
	flex-wrap: wrap;
}

.testicard{
    margin: 20px;
    border: 1px solid #cfcfcf;
    box-shadow: 0px 3px 6px #00000029;
    font-size: 16px;
    color: #707070;
}
.testicard h3{  
    font-size: 18px;
    font-weight: 500;

}
.headerContactContainer{
    margin-left: 50px;
    margin-bottom: 10px;
    margin-top: -10px;
}

@media (max-width: 767px) {
    .headerContactHide {
      display: none;
    }
  }

  .iconbox .customimg2 {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #03a9f4;
    /* padding-top: 5px; */
    margin-right: 10px;
    /* color: #fff; */
}
 
.headerContact-style
{
    font-size: 14px;
    color: #fff;
}

.customimg-mobile-center {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #ffffff;
    /* padding-top: 5px; */
    margin-right: 10px;
    /* color: #fff; */
}
.no-center{
    align-items:flex-start!important;
}