.customNavBar{
	padding: 0;
	background-color:$white-color;
	// padding-bottom: 5px;
	box-shadow:0px 2px 6px 0px #8e8a8a;
	.sticky-top{
		background-color: white;
	}
	.navbar-brand{
		
			padding-left: 15px;
		
		img{
			width: 100%;
			height: auto;
		}
	}
	.header_body{
		width: 100%;
	}
	.header_top{
		font-size: 26px;
		color:#ffffff;
		font-weight: 500;
		display: flex;
		overflow: hidden;
		justify-content:space-between;
		flex-wrap:wrap;
		align-items:center;
		background-color: #03a9f4;
		h2{
			font-size: 30px;
			margin-left: 5px;
			@include media-query-max(1095px)
		{
			font-size: 26px;
			// padding-left: 15px;
		}	
			@include media-query-max(991px)
		{
			padding-left: 15px;
			   padding-right: 15px;
			   font-size: 20px;
			padding-top: 12px;
		}
		}
		@include media-query-min(992px)
				{
					align-items:flex-end;
				}
		.socialIcons{
				background: $light-ash-color;
				padding: 12px;
				position: relative;
				@include media-query-min(992px)
				{
				left: 37px;
			    transform: skew(35deg,0deg);
				}
				@include media-query-max(991px)
				{
					width: 100%;
					padding-left: 15px;

				}
			
		}
		.navbar-toggler{
			    position: absolute;
				top: 34px;
				right: 15px;
		}
	}
	.header_bottom{
		border-top: 1px solid $light-ash-color;
		margin-left: 5px;
		.navbar-nav{
			flex-wrap: wrap;
			padding-top: 10px;
			@include media-query-max(991px)
				{
			padding-top: 0px;
				}
			a{
				color:$black-color;
				font-weight: 500;
				font-size: 16px;
				white-space: nowrap;
				padding: 0px 25px 15px 0px;
				position:relative;
				outline: 0;
				text-decoration: none;
				@include media-query-max(1095px)
				{
					// font-size:15px;
				// padding: 10px 15px 10px 0px;
				// font-size: 16px;
				}	
				i{
					position: relative;
				    top: 1px;
				    font-size: 13px;
				    opacity: 0;
				    right: -5px;
				    @include media-query-max(991px){
				    	position:absolute;
				    	right:5px;
				    	height: 15px;
				    	font-size: 16px;
				    	margin: auto 0;
				    	top:0;
				    	bottom:0;
				    	transform: rotate(270deg);
				    }
				}
				@include media-query-max(991px)
				{
				border-bottom: 1px solid $light-ash-color;
	    			padding: 14px 14px;
	    			width: 100%;
	    			padding-right: 25px;
				}
				&:hover{
					color:$primary-color;
					&.icon{

					i{
					opacity: 1;
					}
					}
				}
				&.activeblue{
					color:$primary-color;
				}
				&.activenav{
					color:$primary-color;
					&.icon{

					i{
					opacity: 1;
					}
					}
				}
				&:active{
					color:$primary-color;
					&.icon{

					i{
					opacity: 1;
					}
					}
				}
			}
		}
		@include media-query-max(991px){
			display: none;
		}
		
	}
	.menuchildrendiv{
		@include media-query-max(992px){
			display: none!important;
		}
		position:fixed;
		background:$white-color;
		z-index: 1023;
		left:0;
		width: 100%;
		top:120px;
		// min-height: 300px;
		.navbar-nav{
			background:$primary-color;
			padding-top:0;
			a{
				background:transparent;
				border:0;
				color:$white-color;
				// opacity: 0.5;
				padding:12px;
				&:hover{
					color:$white-color;
					opacity: 1;
				}
				&:focus{
					color:$white-color;
					border:0;
					outline:0;
				}
				&:active{
					color:$white-color;
				}
				&.active{
					color:$white-color;
					opacity: 1;
				}
				&.disabled{
					color:$white-color;
				}
			}
			&.menu-col-4{
			justify-content: space-around;
			align-items:center;
				a{
					width: 16.66%;
					text-align:center;
					    white-space: pre-wrap;
				}
				&.blacktab{
					a{
						color:$black-color;
						font-weight: bold;
						&.active{
							color:$white-color;
						}
						&:hover{
							color:$white-color;
						}
					}
				}
			}
			&.menu-col-6{
				a{
					width: 16.66%;
					text-align:center;
				}
			}
		}

	}
}
//sociallists
ul.sociallists{
				margin: 0;
				display: flex;
				padding: 0;
			    display: flex;
			    flex-wrap: wrap;

				li{
					display: inline-block;
					width: 45px;
					height: 45px;
					border-radius: 50%;
					background: $white-color;
					text-align: center;
					margin-right: 18px;
					position:relative;
					margin-top: 5px;
					margin-bottom: 5px;
					a{
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
					  i{
					  	font-size: 25px;
					  	position: relative;
    					top: 2px;
    					top: 4px;
    					@include media-query-max(768px){
    					}
					  }
					}
					
					&.facebook{
						box-shadow: 0px 1px 4px $facebook-color;
						i{
							color:$facebook-color
						}
					  	&:hover{
						background-color:$facebook-color;
						i{
							color:$white-color;
						}
					  	}
					  }
					  &.youtube{
						box-shadow: 0px 1px 4px $youtube-color;

					  	i{
							color:$youtube-color
						}
					  	&:hover{
						background-color:$youtube-color;
						i{
							color:$white-color;
						}
					  	}
					  }
					  &.twitter{
						box-shadow: 0px 1px 4px $twitter-color;

					  	i{
							color:$twitter-color;
						}
					  	&:hover{
						background-color:$twitter-color;
						i{
							color:$white-color;
						}
					  	}
					  }
					  &.insta{
						box-shadow: 0px 1px 4px $insta-color;

					  	i{
							color:$insta-color;
						}
					  	&:hover{
						background-color:$insta-color;
						i{
							color:$white-color;
						}
					  	}
					  }
					  &.in{
						box-shadow: 0px 1px 4px $in-color;

					  	i{
							color:$in-color;
						}
					  	&:hover{
						background-color:$in-color;
						i{
							color:$white-color;
						}
					  	}
					  }
				}
				@include media-query-min(992px)
			    {
				    transform: skew(-35deg,0deg);
					padding-right: 37px;
					padding-left: 43px;
				}
				&.removetransform{
						transform:skew(0);
						padding:0;
						li{
							border-radius:0;
							box-shadow:none;
							background-color:$white-color;
							opacity:0.5;
							width: 40px;
							height: 40px;
							a{
								i{
									top:10px;
    								font-size: 21px;
								}
							}
							&:hover{
								background-color:$white-color;
								opacity:1;
								a{
									i{
										color:initial;
									}
								}
								&.facebook{
									i{
										color:$facebook-color;
									}
								}
								&.twitter{
									i{
										color:$twitter-color;
									}
								}
								&.insta{
									i{
										color:$insta-color;
									}
								}
								&.whatsapp{
									i{
										color:$whatsapp-color;
									}
								}&.in{
									i{
										color:$in-color;
									}
								}
							}
						}
					}
			}
			.toggleanimateclass.left {

    // width: 100% !important;
    left: 0 !important;
    height: auto !important;
  }

  .toggleanimateclass.right {

    // width: 100% !important;
    height: auto !important;
    right: 0 !important;
  }

  .animatedtransition.left {
    position: relative;
    left: -1000px;
    height: 0;
    transition: 0.3s;
    overflow-x: hidden;
  }

  .animatedtransition.right {
    position: relative;
    right: -1000px;
    height: 0;
    transition: 0.3s;
    overflow-x: hidden;
  }
  .mobilemoreDiv{
  	.listanchortag{
  		text-decoration: none;
  		color:inherit;
  		&:focus,&.active{
  			text-decoration: none;
  			color:inherit;
  			background:inherit;
  			border: 1px solid rgba(0,0,0,.125);
  			border-top:0;

  		}
  	}
  	// pad
  	display: none;
  	overflow: scroll;
  	@include media-query-max(991px){
  	display: block;
  	width: 400px;
  	}@include media-query-max(500px){

  	width: 350px;
  	}
  	@include media-query-max(400px){

  	width: 300px;
  	}
  	// padding:15px 15px;
  	.btn-group{
  		width: 100%;
  	}
  	.backnavdiv{
  		font-size: 18px;
  		color: black;
		padding:14px 14px;
  		padding-top:20px;
  		// padding-bottom:5px;
  		font-weight: bold;
  	}
  	a.listitem{
  		text-decoration: none;
  		outline: 0;
  		color:$black-color;
  			font-size: 18px;
  			position:relative;
  		i{
  			padding-right:5px;
  			position:absolute;
				    	right:5px;
				    	height: 15px;
				    	font-size: 16px;
				    	margin: auto 0;
				    	top:0;
				    	bottom:0;
				    	transform: rotate(270deg);
  		}
  		// font-size: 16px;
  		&.listitem{
  			width: 100%;
  			display: block;
  		padding:0;
  		padding:14px;	
  		border-bottom: 1px solid $light-ash-color;
  		color:$black-color;
  		&:hover{
  			color:$primary-color;
  		}
  	}
  	}
  	li{
  		&.list-group-item{
  			font-weight: normal;
  			h6{
  				margin-bottom: 0;
  			}
  		&.active{
  			background-color:$primary-color;
  			border-radius:0;
  			border:$primary-color;
  		}
  		&.first{
  			font-weight: bold;
  		}
  	}
  	a{
  		text-decoration: none;
  		color:$black-color;
  	}
  	}
  }
  .rounderimage{
  	width: 50px;
  	height: 50px;
  }
  .flexrow{
  	*{
  		padding:0;
  		margin: 0;
  	}
  	display: flex;
  	align-items:center;
  	img{
  		margin-right: 12px;
  	}
  }