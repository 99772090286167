.slider_indicators{
    .carousel-indicators{
        display: block; 
    justify-content:unset ;
    padding-left: 40px; 
     margin-right: 0;
     margin-left: 0; 
    // /* list-style: none;
    }
    .carousel-indicators li{
        width: 12px;
        height: 12px;
        border-radius: 100%;
        opacity: 1;
    }
    .carousel-indicators .active{
        background-color: #03A9F4;
    }
    .slider_title{
        top:60%;
        left:5%;
        right: unset;
        bottom: unset;
        background: #ff6d05b8 0% 0% no-repeat padding-box;
        box-shadow: 0px 1px 15px #0000001A;
        border-radius: 3px;
        opacity: 0.75;
        color: #fff;
        font-size: 15px;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-right: 30px;
        padding-left: 30px;
        opacity: 1.5;
        overflow-wrap:break-word;
        width: 500px;
        text-align: left;
    }
     
      blockquote::before{
        font-family:Arial;
        content: "\201C";
        color:#ffffff;
        font-size:3em;
        position: absolute;
        left: 10px;
        top:-10px;
      }
      
}
.carousel_div{
    position: relative;
    @include media-query-max(991px){
        .slider_indicators{
        display: none;

        }
    }
    .carousel_form{
        position:absolute;
        top:15px;
        right: 15px;
        z-index: 99;
        .iconbox{
            img{
                position:relative;
                top:3px;
            }
        }
        .appointmenttoggle{
            display: none;
            opacity:0;
            transition: 0.4s;
            margin: 25px 0px;
        }
        .curvedcard{
            transition: 0.4s;
            margin:30px 0px;
            width: 400px;
            padding: 30px 15px;
            .showbox{
                opacity:1;
                display: block;
            }
            &.hidebox{
                position: absolute;
                z-index: -1;
                bottom:0;

                opacity:0;

                // display: none;
            }
            .hidebox{
                opacity:0;
                display: none;
            }
            &.certificate{
              padding: 15px 10px;
            }
            >a{
                width: 80%;
                background-color:$primary-color;
                padding:8px 15px;
                color:$white-color;
                border-radius:25px;
                font-size: 16px;
                font-family: robotomedium;
                cursor: pointer;
            }
            .infobox{
                padding-top: 15px;
            }
            .appointmentForm{
                label{
                    width: 92%;
                    margin-bottom: 18px;
                input{
                    border-top-left-radius: 9px;
                    outline: 0;
                    padding: 9px;
                    border: 0;
                    background-color: $ash2-color;
                    width: 100%;
                    color:$ash1-color;
                }
                .errordiv{
                    color: $red-color;
                    text-align: left;
                    position:relative;
                    top: 3px;
                }
            }
            .formbtn{
                padding: 7px 25px;
                outline: 0;
                border: 0;
                min-width: 172px;
                color:$white-color;
            }
            .checkboxlabel{
                display: flex;
                align-items:center;
                *{
                    margin:0;
                    padding:0;
                }
                input{
                    padding:10px;
                }
            }
            }
            .certificatebox{
                display: flex;
                p{
                    font-size: 14px;
                    text-align: left;
                    color: $ash1-color;
                    padding-left: 10px;
                }
            }
        }
        @include media-query-max(1220px){
            position: initial;
            display: flex;
            justify-content: center;
            align-items:center;
            flex-wrap: wrap-reverse;
                margin: 15px;
            .showbox{
                opacity:1;
            }
           
            .curvedcard{
                margin: 0;
                
                 @include media-query-max(768px){
                .appointmentForm{
                label{
                    width: 100%;
                }
            }
            .certificatebox{
                flex-wrap: wrap;
                justify-content: center;
            }
            }
            &.hidebox{
                display: block;
                position:relative;
                opacity:1;
            }
                }
            .hidebox{
                display: block;
                position:relative;
                opacity:1;
            }
        }
    }
}

//iconbox
  .iconbox{
                    padding-top:10px;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    width: 80%;
                    margin:0 auto;
                    @include media-query-max(768px){
                        padding-top:35px;
                        width: 100%;
                    }
                    // @include
                    // padding-bottom:20px;
                    &.removejustify{
                    width: 100%;
                        justify-content: flex-start;
                    }
                    .customimg{
                        display: inline-block;
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                        background: $primary-color;
                        padding-top: 5px;
                        margin-right: 23px;
                        @include media-query-max(768px){
                            position:absolute;
                        }
                        // position:absolute;
                        img{
                            width: auto;
                            height: auto;
                        }
                    }
                    span{
                        color:$ash1-color;
                        font-size: 15px;
                        &:nth-child(2){
                            @include media-query-max(768px){
                            padding-left:50px;
                        }
                            text-align: left;
                            word-break: break-word;
                        }
                    }
                }
